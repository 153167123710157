import RouterComponent from './router';
import {GlobalStyle} from './style-App'
function App() {
  return (
    <>
    <GlobalStyle/>
    <RouterComponent/>
    </>
  );
}

export default App;
