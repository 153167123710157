import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { API_URL } from "../../utils/api";

export const BlogPost = createAsyncThunk("Blog/post", async (body) => {
  return await axios.post(`${API_URL}/blog`, body).then((res) => res);
});
export const BlogGet = createAsyncThunk("Blog/get", async () => {
  return await axios
    .get(`${API_URL}/blog`)
    .then((response) => response.data);
});

export const BlogDelete = createAsyncThunk("Blog/delete", async (id) => {
  return await axios
    .delete(`${API_URL}/blog/${id}`)
    .then((response) => response.data);
});
export const BlogPut = createAsyncThunk(
  "Blog/put",
  async ({ body, id }) => {
    return await axios
      .put(`${API_URL}/blog/${id}`, body)
      .then((response) => console.log(response.data));
  }
);

export const UploadImage = createAsyncThunk("Blog/upload", async (e) => {
  const formData = new FormData();
  formData.append("file", e.target.files[0]);
  formData.append("upload_preset", "ujbpbrqb");
  try {
    return await axios
      .post("https://api.cloudinary.com/v1_1/dsua6yygj/upload", formData)
      .then((response) => response?.data.secure_url);
  } catch (error) {
    return error;
  }
});
const BlogSlice = createSlice({
  name: "Blog",
  initialState: {
    BlogGets: {
      loading: false,
      data: [],
      error: false,
      success: false,
    },
    BlogPosts: {
      Success: false,
      Error: false,
      loading: false,
    },
    BlogDeletes: {
      Success: false,
      Error: false,
      loading: false,
    },
    BlogPuts: {
      Error: false,
      Loading: false,
      Success: false,
    },
    uploadBlog: {
      Error: false,
      Loading: false,
      Success: false,
      data: "",
    },
  },
  extraReducers: {
    // get
    [BlogGet.pending]: (state, action) => {
      state.BlogGets.loading = true;
    },
    [BlogGet.fulfilled]: (state, action) => {
      state.BlogGets.loading = false;
      state.BlogGets.success = true;
      state.BlogGets.data = action.payload;
      state.BlogGets.error = false;
    },
    [BlogGet.rejected]: (state, action) => {
      state.BlogGets.loading = false;
      state.BlogGets.error = true;
      state.BlogGets.success = false;
    },
    // add
    [BlogPost.pending]: (state, action) => {
      state.BlogPosts.loading = true;
    },
    [BlogPost.fulfilled]: (state, action) => {
      state.BlogPosts.loading = false;
      state.BlogPosts.Success = true;
      state.BlogPosts.Error = false;
    },
    [BlogPost.rejected]: (state, action) => {
      state.BlogPosts.loading = false;
      state.BlogPosts.Error = true;
      state.BlogPosts.Success = false;
    },
    // delete
    [BlogDelete.pending]: (state, action) => {
      state.BlogDeletes.loadingDelete = true;
    },
    [BlogDelete.fulfilled]: (state, action) => {
      state.BlogDeletes.loading = false;
      state.BlogDeletes.Success = true;
      state.BlogDeletes.Error = false;
    },
    [BlogDelete.rejected]: (state, action) => {
      state.BlogDeletes.loading = false;
      state.BlogDeletes.Error = true;
      state.BlogDeletes.Success = false;
    },
    // put
    [BlogPut.pending]: (state, action) => {
      state.BlogPuts.loading = true;
    },
    [BlogPut.fulfilled]: (state, action) => {
      state.BlogPuts.Error = false;
      state.BlogPuts.Success = true;
      state.BlogPuts.Loading = false;
    },
    [BlogPut.rejected]: (state, action) => {
      state.BlogPuts.Error = true;
      state.BlogPuts.Success = false;
      state.BlogPuts.Loading = false;
    },

    [UploadImage.pending]: (state, action) => {
      state.uploadBlog.Loading = true;
    },
    [UploadImage.fulfilled]: (state, action) => {
      state.uploadBlog.Error = false;
      state.uploadBlog.Success = true;
      state.uploadBlog.Loading = false;
      state.uploadBlog.data = action.payload;
      
    },
    [UploadImage.rejected]: (state, action) => {
      state.uploadBlog.Error = true;
      state.uploadBlog.Success = false;
      state.uploadBlog.Loading = false;
    },
  },
});

export const {} = BlogSlice.actions;
export default BlogSlice.reducer;
